import "./App.css";
import Nav from "./components/Nav";
import Team from "./components/Team";
import React from "react";


const App = () => {  
  return (
    <div className="App relative text-center bg-slate-200 overflow-scroll w-full">
      <Nav />
      <Team name="Indiana Pacers" img="some-img" />
    </div>
  );
};

export default App;
