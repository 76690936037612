import { useState } from "react";
import { slide as Menu } from "@calebegeazi/react-burger-menu";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const Nav = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartOver = (yes) => {
    if (yes) {
      // do nothing
    }
  };

  return (
    <div className="fixed h-11 w-full z-10 -m-px">
      <div className="relative p-2 text-slate-600 shadow top-0 z-50 bg-slate-300">
        <Menu>
          <a id="home" className="menu-item" href="/">
            Home
          </a>
          <a id="Stats" className="menu-item" href="/stats">
            Stats
          </a>
          <a
            id="GitHub"
            className="menu-item"
            href="https://github.com/CalebeGeazi/TBD"
            target="_blank"
            rel="noreferrer"
          >
            Fork me on Github!
          </a>
        </Menu>
        <div className="text-lg sm:text-2xl md:text-3xl font-bold">
        Django React Tailwindcss
        </div>
        <div className="absolute -top-1 -right-1">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleClickOpen}
          >
            <LocalFireDepartmentIcon color="warning" sx={{ fontSize: 32 }} />
          </IconButton>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Do you want to start over?
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                handleStartOver(false);
              }}
            >
              No
            </Button>
            <Button
              onClick={() => {
                handleClose();
                handleStartOver(true);
              }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {props.children}
    </div>
  );
};

export default Nav;
