import "./Team.css";

const Team = (props) => {
  return (
    <div className="team border-1 mt-16">
      <div>{props.name}</div>
      <div>{props.img}</div>
    </div>
  );
};

export default Team;